var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"sidebar-group-handler","sidebar-class":"sidebar-lg","visible":_vm.isGroupSidebarActive,"bg-variant":"white","shadow":"","backdrop":"","no-header":"","right":""},on:{"change":_vm.toggleSidebar},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.groupLocal.id ? "Editar" : "Cadastrar")+" "+_vm._s(_vm.groupTitle)+" ")]),_c('div',[_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1)]),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.formValidation.resetForm($event)}}},[_c('validation-provider',{attrs:{"name":"Nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label-for":"name"}},[_c('template',{slot:"label"},[_vm._v(" Nome"),_c('span',{staticClass:"text-danger"},[_vm._v(" * ")])]),(!_vm.loading)?_c('b-form-input',{attrs:{"id":"name","autofocus":"","state":_vm.formValidation.getValidationState(validationContext),"trim":"","disabled":_vm.loading || _vm.saving},model:{value:(_vm.groupLocal.name),callback:function ($$v) {_vm.$set(_vm.groupLocal, "name", $$v)},expression:"groupLocal.name"}}):_vm._e(),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],2)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Código","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label-for":"code"}},[_c('template',{slot:"label"},[_vm._v(" Código"),_c('span',{staticClass:"text-danger"},[_vm._v(" * ")])]),(!_vm.loading)?_c('b-form-input',{attrs:{"id":"code","autofocus":"","state":_vm.formValidation.getValidationState(validationContext),"trim":"","disabled":_vm.loading || _vm.saving,"type":"number"},model:{value:(_vm.groupLocal.code),callback:function ($$v) {_vm.$set(_vm.groupLocal, "code", $$v)},expression:"groupLocal.code"}}):_vm._e(),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],2)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Grupo Econômico","rules":_vm.hasEconomicGroup ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [(_vm.hasEconomicGroup)?_c('b-form-group',{class:{
              'is-invalid':
                _vm.formValidation.getValidationState(validationContext) ===
                false,
              'is-valid':
                _vm.formValidation.getValidationState(validationContext),
            },attrs:{"label-for":"economic-group"}},[_c('template',{slot:"label"},[_vm._v(" Grupo Econômico"),_c('span',{staticClass:"text-danger"},[_vm._v(" * ")])]),_c('v-select',{attrs:{"input-id":"economic-group","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.groupsEconomicsOptions,"reduce":_vm.reduceValue,"required":_vm.hasEconomicGroup,"disabled":_vm.loading || _vm.saving,"clearable":false},model:{value:(_vm.groupLocal.economicGroupId),callback:function ($$v) {_vm.$set(_vm.groupLocal, "economicGroupId", $$v)},expression:"groupLocal.economicGroupId"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" Desculpe, não há opções correspondentes ")])]),_c('b-form-invalid-feedback',{attrs:{"state":_vm.formValidation.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],2):_vm._e()]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{class:{
              'is-invalid':
                _vm.formValidation.getValidationState(validationContext) ===
                false,
              'is-valid':
                _vm.formValidation.getValidationState(validationContext),
            },attrs:{"label-for":"Status"}},[_c('template',{slot:"label"},[_vm._v(" Status"),_c('span',{staticClass:"text-danger"},[_vm._v(" * ")])]),(_vm.loading)?_c('AvSkeletonInput'):_vm._e(),(!_vm.loading)?_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.statusOptions,"reduce":_vm.reduceValue,"clearable":false,"label":"label","input-id":"status","disabled":_vm.loading || _vm.saving,"state":_vm.formValidation.getValidationState(validationContext)},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
            var label = ref.label;
return [_c('span',[_vm._v(" "+_vm._s(label))])]}}],null,true),model:{value:(_vm.groupLocal.status),callback:function ($$v) {_vm.$set(_vm.groupLocal, "status", $$v)},expression:"groupLocal.status"}}):_vm._e(),_c('b-form-invalid-feedback',{attrs:{"state":_vm.formValidation.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],2)]}}],null,true)}),_c('div',{staticClass:"d-flex mt-2"},[_c('av-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit","loading":_vm.saving,"disabled":_vm.loading || _vm.saving}},[_vm._v(" "+_vm._s(_vm.saving ? "Salvando..." : "Salvar")+" ")]),_c('av-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"reset","variant":"outline-secondary","disabled":_vm.loading || _vm.saving},on:{"click":hide}},[_vm._v(" Cancelar ")])],1)],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }