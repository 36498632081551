














































































































































































































import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BAvatar,
  BFormTextarea,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { AvButton } from "@/components";
import { AvSkeletonInput } from "@/components/av-skeleton";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

// Services
import { useAuth, useGroup, useGroupEconomic } from "@/services";

// Interfaces
import { IOption } from "@/@core/services/interfaces/IUtil";
import {
  IBodyCreateGroup,
  IBodyUpdateGroup,
  IResponseGetGroup,
} from "@core/services/interfaces/business/group/IGroupService";
import { IResponseListGroupEconomic } from "@core/services/interfaces/business/group-economic/IGroupEconomicService";

interface IGroup {
  id?: number;
  name: string;
  code: number | null;
  status: boolean | null;
  economicGroupId?: number;
}

@Component({
  name: "GroupHandler",
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BAvatar,
    BFormTextarea,
    BFormInvalidFeedback,
    AvButton,
    AvSkeletonInput,
    vSelect,
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isGroupSidebarActive",
    event: "update:is-group-handler-sidebar-active",
  },
})
export default class GroupHandler extends Vue {
  // Props
  @Prop({ required: true }) isGroupSidebarActive!: boolean;
  @Prop({ required: true }) groupId!: number | null;

  // Data
  groupTitle =
    this.$store.state["appConfig"].params.GRUPO_EMPRESA_TITLE ||
    "Grupo Empresa";
  groupsEconomicsOptions: IOption[] = [];
  groupLocal: IGroup = {
    id: undefined,
    name: "",
    code: null,
    status: null,
    economicGroupId: undefined,
  };

  domain = useAuth.getDomain();
  required = required;
  blankGroup = {
    id: undefined,
    name: "",
    code: null,
    status: null,
    economicGroupId: null,
  };
  loading = false;
  saving = false;

  statusOptions: IOption[] = [
    { label: "Ativo", value: true },
    { label: "Inativo", value: false },
  ];

  resetData = () => {
    this.groupLocal = JSON.parse(JSON.stringify(this.blankGroup));
  };

  formValidation = formValidation(this.resetData);

  // Computeds
  get hasEconomicGroup(): boolean {
    const has =
      this.$store.state["appConfig"].params.EMP_ASSOCIACAO_GRUPO_ECONOMICO;

    return has ? (has == "S" ? true : false) : true;
  }

  // Lifecycle
  created() {
    if (this.hasEconomicGroup) this.fetchGetGroupsEconomic();
  }

  // Watchs
  @Watch("groupId", { immediate: true })
  watchGroupId() {
    if (typeof this.groupId == "number") {
      this.fetchGetGroupById(this.groupId);
    } else {
      this.formValidation.resetForm();
    }
  }

  // Methods
  fetchGetGroupById(id: number) {
    this.loading = true;
    useGroup
      .requestGetByIdGroup(id)
      .then((response: IResponseGetGroup) => {
        if (!(response.data.Status == 200 || response.data.Status == 201)) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Falha ao consultar os dados do grupo!",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }

        const data = response.data.Data;

        this.groupLocal = {
          id: data.Id,
          code: data.Codigo,
          economicGroupId: data.GrupoEconomicoId,
          name: data.Nome,
          status: data.Ativo,
        };
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error ao consultar " + this.groupTitle,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  fetchGetGroupsEconomic() {
    this.loading = true;
    useGroupEconomic
      .requestGet()
      .then((response: IResponseListGroupEconomic) => {
        this.groupsEconomicsOptions = response.data.data.map((item) => ({
          label: item.Nome,
          value: item.Id,
        }));
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error ao consultar os grupos econômicos",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  toggleSidebar(val: boolean) {
    this.$emit("update:is-group-handler-sidebar-active", val);
  }

  clearGroupData() {
    this.groupLocal = JSON.parse(JSON.stringify(this.blankGroup));
  }

  reduceValue(option: IOption) {
    return option.value;
  }

  fetchUpdateGroup(group: IBodyUpdateGroup) {
    this.saving = true;
    useGroup
      .requestUpdateGroup(group)
      .then((response: any) => {
        if (response.data.Status == 200 || response.data.Status == 201) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.groupTitle + " atualizado com sucesso!",
              icon: "CheckIcon",
              variant: "success",
            },
          });

          this.$emit("refresh-data");
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Ocorreu um erro inesperado!",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error ao atualizar " + this.groupTitle,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.$emit("update:is-group-handler-sidebar-active", false);
        this.saving = false;
      });
  }

  fetchCreateGroup(group: IBodyCreateGroup) {
    this.saving = true;
    useGroup
      .requestCreateGroup(group)
      .then((response: any) => {
        if (response.data.Status == 200 || response.data.Status == 201) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.groupTitle + " inserido com sucesso!",
              icon: "CheckIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Ocorreu um erro inesperado!",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error ao inserir " + this.groupTitle,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.$emit("update:is-group-handler-sidebar-active", false);
        this.saving = false;
      });
  }

  onSubmit() {
    if (typeof this.groupId == "number") {
      // Update
      this.fetchUpdateGroup({
        id: this.groupLocal.id || -1,
        nome: this.groupLocal.name,
        codigo: this.groupLocal.code || 0,
        ativo: this.groupLocal.status || false,
        grupoEconomicoId: this.groupLocal.economicGroupId,
      });
    } else {
      // Insert
      this.fetchCreateGroup({
        ativo: this.groupLocal.status || false,
        codigo: this.groupLocal.code || 0,
        grupoEconomicoId: this.groupLocal.economicGroupId,
        nome: this.groupLocal.name,
      });
    }
  }
}
